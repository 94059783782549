import fetch from "../auth/FetchInterceptor";

const AppControlService = {}

AppControlService.createNewControl = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createControl',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

AppControlService.updateControl = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateAppControl',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

AppControlService.getControlData = function (data) {
    return fetch({
        crossDomain:true,
        url: '/getOneAppControl/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

AppControlService.deleteControl = function (id) {
    return fetch({
        crossDomain:true,
        url: '/removeAppControl/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

AppControlService.changeAppControlStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateAppControlStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

AppControlService.getMode = function () {
    return fetch({
        crossDomain:true,
        url: '/mode',
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
        },
    })
}
AppControlService.updateMode = function (data) {
    console.log("DATAAAA", data)
    return fetch({
        crossDomain:true,
        url: '/mode',
        method: 'patch',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
        },
        data: JSON.stringify(data)
    })
}

export default AppControlService