import {
    NEWSOCIALLINK,SHOW_LOADING,UPDATESOCIALLINK
} from "../constants/SocialLinks";
import {
    AUTH_TOKEN, HIDE_AUTH_MESSAGE, SHOW_AUTH_MESSAGE
} from "../constants/Auth";

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN),
}

const socialLinks = (state = initState, action) => {
    switch (action.type) {
        case NEWSOCIALLINK:
            return {
                ...state,
                loading: false,
                message: action.message,
                showMessage: true,
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case UPDATESOCIALLINK:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false,
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        default:
            return state;
    }
}

export default socialLinks