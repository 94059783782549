import fetch from "../auth/FetchInterceptor";

const LanguageService = {}

LanguageService.createNewLang = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createLanguage',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

LanguageService.updateLang = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateLanguages',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

LanguageService.getLangData = function (data) {
    return fetch({
        crossDomain:true,
        url: '/languageData/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

LanguageService.deleteLang = function (id) {
    return fetch({
        crossDomain:true,
        url: '/deleteLanguage/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

LanguageService.changeLangStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateLanguageStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}


LanguageService.getLangScreen = function (data) {
    return fetch({
        crossDomain:true,
        url: '/languageScreenData/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

LanguageService.updateLangScreen = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateLanguageScreen',
        method: 'post',
        data:data,
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

LanguageService.createLanguageString = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createLanguageString',
        method: 'post',
        data:data,
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

LanguageService.updateLanguageString = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateLanguageString',
        method: 'post',
        data:data,
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}



LanguageService.createLanguageScreen = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createLanguageScreen',
        method: 'post',
        data:data,
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

LanguageService.deleteLangScreen = function (data) {
    return fetch({
        crossDomain:true,
        url: '/deleteLanguageScreen/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

// get all Languages
LanguageService.getAllLanguages = function () {
    return fetch({
        crossDomain:true,
        url: '/getAllLanguages',
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

// get all Languages screen
LanguageService.getAllLanguageScreens = function () {
    return fetch({
        crossDomain:true,
        url: '/getAllLanguageScreens',
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}


// get all Languages screen
LanguageService.getLangStrDataSingle = function (data) {
    return fetch({
        crossDomain:true,
        url: '/languageStringData/'+data,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

// get all Languages screen
LanguageService.deleteLanguageString = function (data) {
    return fetch({
        crossDomain:true,
        url: '/deleteLanguageString/'+data,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

export default LanguageService