import {AUTH_TOKEN, HIDE_AUTH_MESSAGE, SHOW_AUTH_MESSAGE} from "../constants/Auth";
import {NEWGATEWAY,SHOW_LOADING,UPDATEGATEWAY} from "../constants/Gateway";

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN),
}

const gateway = (state = initState, action) => {
    switch (action.type) {
        case NEWGATEWAY:
            return {
                ...state,
                loading: false,
                message: action.message,
                showMessage: true,
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case UPDATEGATEWAY:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false,
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        default:
            return state;
    }
};
export default gateway