import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import AppDiscountService from 'services/AppDiscountService'
import {CREATE_DISCOUNT, FETCH_DISCOUNT,FETCH_DISCOUNT_ONE,UPDATE_DISCOUNT,DELETE_DISCOUNT} from "../actions/AppDiscount";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* listSaga() {
    yield takeEvery(FETCH_DISCOUNT, function* ({payload}) {
        try {
            const user = yield call(AppDiscountService.list, payload);
            
            return user
        } catch (err) {
            console.log(err);
            return {};
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(listSaga),
    ]);
}
