import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import SMTPService from 'services/SMTPService'
import {NEWSMTP, UPDATESMTP} from "../constants/SMTPCredentials";
import {showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* createNewSMTP() {
    yield takeEvery(NEWSMTP, function* ({payload}) {
        try {
            const user = yield call(SMTPService.createNewSMTP, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('SMTP created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisSMTP() {
    yield takeEvery(UPDATESMTP, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(SMTPService.updateSMTP, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('SMTP Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewSMTP),
        fork(updateThisSMTP)
    ]);
}
