import React, { useEffect } from "react";
import {injectIntl } from "react-intl";
import t from './../../../utils/translate';
const FormattedMessage = (props)=>{
  return <>{t(props.id)}</>
}

const formattedMessageFunc = (id)=>{
  return t(id)
}

const IntlMessage = props =>{
  return <FormattedMessage {...props}  />
};

export { formattedMessageFunc };

export default injectIntl(IntlMessage, {
  withRef: false,
});
