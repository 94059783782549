// filterReducer.js

// Initial state
const initialState = {
  searchText: null,
  status: null,
  privacy: null,
  host: null,
  type: null,
  intensity: null,
};

// Reducer
const filter = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default filter;
