import fetch from "../auth/FetchInterceptor";

const SocialLinkService = {}

SocialLinkService.createNewSocialLink = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createSocialLink',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-Type': 'multipart/form-data',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

SocialLinkService.updateSocialLink = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateSocialLink',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

SocialLinkService.getSocialLinkData = function (data) {
    return fetch({
        crossDomain:true,
        url: '/getOneSocialLink/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

SocialLinkService.deleteSocialLink = function (id) {
    return fetch({
        crossDomain:true,
        url: '/removeSocialLink/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

SocialLinkService.changeSocialLinkStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateSocialLinkStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

export default SocialLinkService