import antdarEG from 'antd/es/locale/ar_EG';
import arMsg from "../locales/ar_AR.json";

const arLang = {
  antd: antdarEG,
  locale: 'ar-AR',
  messages: {
    ...arMsg
  },
};
export default arLang;
