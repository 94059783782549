import React, {useState} from "react";
import {Menu, Dropdown, Badge, Avatar, List, Button} from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import notificationData from "assets/data/notification.data.json";
import Flex from "components/shared-components/Flex";
import NotificationService from "./../../services/NotificationService";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const getIcon = icon => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

const getNotificationBody = list => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
          <Link to={"/app/cms/bookings/" + item.details.bookingId}>
        <List.Item className="list-clickable">
            <Flex alignItems="center">
              <div className="mr-3">
                <span className="text-dark">{item.message} </span>
              </div>
            </Flex>
        </List.Item>
          </Link>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    NotificationService.getUnReadNotification({
      limit: 10,
      page: 1,
    }).then(list => {
      setData(list.records);
      setCount(list.count);
    });
  }, []);

  const handleVisibleChange = flag => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button
          type="link"
          onClick={() => {
            NotificationService.updateNotification().then(list => {
              setData([]);
            });
          }}
          size="small"
        >
          Read All{" "}
        </Button>
      </div>
      <div className="nav-notification-body" onClickCapture={()=>{
        handleVisibleChange(!visible);
      }} >{getNotificationBody(data)}</div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          <Link className="d-block" to="/app/notification">
            View all
          </Link>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={count}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
