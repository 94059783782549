import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'
import {AUTH_LOCALE, THEME_DIR} from '../redux/constants/Auth';

export const APP_NAME = 'Fohgah Admin panel';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const BASE_URL = env.BASE_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/admin';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: localStorage.getItem(AUTH_LOCALE) ? localStorage.getItem(AUTH_LOCALE) : 'EN',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: localStorage.getItem(THEME_DIR) ? localStorage.getItem(THEME_DIR) : DIR_LTR,
};
