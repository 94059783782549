import {AUTH_LOCALE} from "redux/constants/Auth";

const {default: axios} = require("axios");
const {API_BASE_URL} = require("configs/AppConfig");

function t(key) {
  const langData = localStorage.getItem("lang-fohgah-data");
  const getAllStrings = JSON.parse(langData);
  const lang = localStorage.getItem(AUTH_LOCALE);
  // if(key === 'sidenav.dashboard'){
  //     debugger
  // }
  if (getAllStrings && getAllStrings[key]) {
    return getAllStrings[key][String(lang).toUpperCase()];
  }
  return key;
}

function translateStr(key, fieldValue = "") {
  const langData = localStorage.getItem("lang-fohgah-data");
  const getAllStrings = JSON.parse(langData);
  const lang = localStorage.getItem(AUTH_LOCALE);

  if (key) {
    const set = "setDATA";
    const langDATA = localStorage.getItem(set);
    if (langDATA) {
      const ObjValue = JSON.parse(localStorage.getItem(set));
      localStorage.setItem(
        set,
        JSON.stringify({
          ...ObjValue,
          [key]: {
            en: fieldValue && fieldValue != "" ? fieldValue : key,
            ar: fieldValue && fieldValue != "" ? fieldValue : key,
          },
        })
      );
    } else {
      const newData = localStorage.getItem("NEW_DATA")
       let newDataJson = {}

       try {
        newDataJson = JSON.parse(newData);
       } catch (error) {
        
       }
       if(fieldValue != ''){
         localStorage.setItem(
           "NEW_DATA",
           JSON.stringify({
             ...newDataJson,
             [key]: {
               en: fieldValue && fieldValue != "" ? fieldValue : key,
               ar: fieldValue && fieldValue != "" ? fieldValue+"-ar" : key,
             },
           })
         );
       }
    }
  }

  // if(key === 'sidenav.dashboard'){
  //     debugger
  // }
  if (getAllStrings && getAllStrings[key]) {
    return getAllStrings[key][String(lang).toUpperCase()];
  }
  return key;
}

const getCurrentLangValue = (LangObj)=>{
  const lang = localStorage.getItem(AUTH_LOCALE);
  // debugger
  if(LangObj == null || typeof LangObj !=  "object"){
    if(typeof LangObj ==  "string") return LangObj
    return '-'
  }
  if(LangObj[lang]){
      return LangObj[lang]
  }
  return LangObj['en']
}

export default translateStr;
export {translateStr, getCurrentLangValue};
