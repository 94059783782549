import { all } from 'redux-saga/effects';
import Auth from './Auth';
import User from './Users';
import Gateway from './PaymentGateway';
import Language from './GlocalLanguages';
import AppControl from './AppControls';
import SocialLinks from './SocialLinks';
import FCMCredentials from './FCMCredentials';
import SMTPCredentials from './SMTPCredentials';
import Admins from './Admins';
import AppDiscount from './AppDiscount';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    User(),
    Gateway(),
    Language(),
    AppControl(),
    SocialLinks(),
    FCMCredentials(),
    SMTPCredentials(),
    Admins(),
    AppDiscount(),
  ]);
}
