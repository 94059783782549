import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import AdminService from 'services/AdminService'
import {NEWADMIN, UPDATEADMIN} from "../constants/Admins";
import {CHANGE_LOCALE_DIRECTION} from "../constants/Theme";
import {authenticated, showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";
import {DIR_LTR, DIR_RTL} from "../../constants/ThemeConstant";
import JwtAuthService from 'services/JwtAuthService';

export function* createNewAdmin() {
    yield takeEvery(NEWADMIN, function* ({payload}) {
        try {
            const user = yield call(AdminService.createNewAdmin, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('Admin created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisAdmin() {
    yield takeEvery(UPDATEADMIN, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(AdminService.updateAdmin, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('Admin Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateLocale() {
    yield takeEvery(CHANGE_LOCALE_DIRECTION, function* (action) {
        try {
            const user = yield call(AdminService.changeAdminLocale, {locale: action.locale});
            const langs = yield call(JwtAuthService.getAllLanguageStringsByLang, {
                pageIndex: 1,
                limit: 1000,
              });
            if (user.user_type === 'is_admin'){
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);

                localStorage.setItem(AUTH_LOCALE, user.locale);
                // debugger
                if (user.locale === 'ar'){
                    window.location.reload(false);
                    localStorage.setItem(THEME_DIR, DIR_RTL);
                }else{
                    window.location.reload(false);
                    localStorage.setItem(THEME_DIR, DIR_LTR);
                }
                yield put(authenticated(user));
            }else{
                yield put(showAuthMessage('Not an admin'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewAdmin),
        fork(updateThisAdmin),
        fork(updateLocale)
    ]);
}
