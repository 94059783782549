import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.loginRequest = function (email, password) {
	return fetch({
		url: '/adminLogin',
		method: 'post',
		headers: {
				'Accept': 'application/json',
				'public-request': 'true',
				'Content-type': 'application/json',
				'Origin': '',
			},
		data: JSON.stringify({
			'email': email,
			'password': password
		})
	})
}

JwtAuthService.getAllLanguageStringsByLang = async function ({pageIndex,limit}) {
	// debugger
	const response = await getAllLangData(pageIndex,limit)
	return response
}
function getAllLangData(pageIndex,limit){
	return fetch({
		url: '/getAllLangStrInAll?pageIndex='+pageIndex+'&limit='+limit,
		method: 'get',
		headers: {
				'Accept': 'application/json',
				'public-request': 'true',
				'Content-type': 'application/json',
				'Origin': '',
			},
	}).then(async (res)=>{
		const data = localStorage.getItem('lang-fohgah-data');
		if(data){
			const oldData = JSON.parse(data);
			localStorage.setItem('lang-fohgah-data',JSON.stringify({ ...oldData, ...res.language_strings }));
		} else {
			localStorage.setItem('lang-fohgah-data',JSON.stringify(res.language_strings));
		}
		if(res.hasMore){
			await getAllLangData(pageIndex+1,limit)
		}
	})
}

JwtAuthService.signOutRequest = function () {
	return fetch({
		url: '/logout',
		method: 'get',
	})
}
export default JwtAuthService