import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import LanguageService from 'services/LanguageService'
import {NEWLANG, UPDATELANG} from "../constants/GlobalLanguages";
import {showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* createNewLang() {
    yield takeEvery(NEWLANG, function* ({payload}) {
        try {
            const user = yield call(LanguageService.createNewLang, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('User created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisLang() {
    yield takeEvery(UPDATELANG, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(LanguageService.updateLang, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('User Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewLang),
        fork(updateThisLang)
    ]);
}
