import fetch from "../auth/FetchInterceptor";

const AppDiscountServices = {}

AppDiscountServices.create = function (data) {
    return fetch({
        crossDomain:true,
        url: '/AppDiscount/create',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

AppDiscountServices.update = function (data) {
    return fetch({
        crossDomain:true,
        url: '/AppDiscount/update',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

AppDiscountServices.delete = function (id) {
    return fetch({
        crossDomain:true,
        url: '/AppDiscount/delete/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

AppDiscountServices.list = function () {
    return fetch({
        crossDomain:true,
        url: '/AppDiscount/list',
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}
AppDiscountServices.listOne = function (id) {
    return fetch({
        crossDomain:true,
        url: '/AppDiscount/list/'+id,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

export default AppDiscountServices