import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import UserService from 'services/UserService'
import {NEWUSER, UPDATEUSER} from "../constants/Users";
import { showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* createNewUser() {
    yield takeEvery(NEWUSER, function* ({payload}) {
        try {
            const user = yield call(UserService.createNewUser, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('User created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisUser() {
    yield takeEvery(UPDATEUSER, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(UserService.updateUser, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('User Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewUser),
        fork(updateThisUser),
    ]);
}
