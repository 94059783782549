import fetch from "../auth/FetchInterceptor";

const UserService = {}

UserService.createNewUser = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createUser',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

UserService.updateUser = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateUser',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

UserService.getUserData = function (data) {
     return fetch({
         crossDomain:true,
        url: '/userData/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
     });
}

UserService.deleteUser = function (id) {
    return fetch({
        crossDomain:true,
        url: '/deleteUser/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

UserService.changeUserStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

UserService.changeUserLocale = function () {
    return fetch({
        crossDomain:true,
        url: '/updateAdminLocale/608bb75f8bdc504610e9e316',
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

UserService.removeUploadedImage = function (data) {
    return fetch({
        crossDomain:true,
        url: '/upload/remove',
        data,
        method: 'post',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}
export default UserService