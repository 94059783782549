import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import AppControlService from 'services/AppControlService'
import {NEWCONTROL, UPDATECONTROL} from "../constants/AppControls";
import {showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* createNewControl() {
    yield takeEvery(NEWCONTROL, function* ({payload}) {
        try {
            const user = yield call(AppControlService.createNewControl, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('App Control created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisControl() {
    yield takeEvery(UPDATECONTROL, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(AppControlService.updateControl, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('App Control Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewControl),
        fork(updateThisControl)
    ]);
}
