
export const FETCH_DISCOUNT = 'AppDiscount/Fetch/All'
export const FETCH_DISCOUNT_SUCCESS = 'AppDiscount/FetchAll/Success'
export const FETCH_DISCOUNT_ONE = 'AppDiscount/Fetch/one'

export const CREATE_DISCOUNT = 'AppDiscount/Create'
export const CREATE_DISCOUNT_SUCCESS = 'AppDiscount/Create/success'

export const UPDATE_DISCOUNT = 'AppDiscount/UPDATE_DISCOUNT'
export const DELETE_DISCOUNT = 'AppDiscount/DELETE_DISCOUNT'
export const LOADING = 'AppDiscount/loading'

export const fetchAllDiscount = (payload) => {
    return {
        type: FETCH_DISCOUNT,
        payload
    }
};


export const fetchOneDiscount = (payload) => {
    return {
        type: FETCH_DISCOUNT_ONE,
        payload
    }
};


export const createDiscount = (payload) => {
    return {
        type: CREATE_DISCOUNT,
        payload
    }
};
export const createDiscountSuccess = (payload) => {
    return {
        type: CREATE_DISCOUNT_SUCCESS,
        payload
    }
};

export const updateDiscount = (payload) => {
    return {
        type: UPDATE_DISCOUNT,
        payload
    }
};

export const deleteDiscount = (payload) => {
    return {
        type: DELETE_DISCOUNT,
        payload
    }
};

export const showLoading = () => {
    return {
        type: LOADING,
    };
};
