import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import FCMService from 'services/FCMService'
import {NEWFCM, SENDFCM, UPDATEFCM} from "../constants/FCMCredentials";
import {showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* createNewFCM() {
    yield takeEvery(NEWFCM, function* ({payload}) {
        try {
            const user = yield call(FCMService.createNewFCM, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('FCM created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisFCM() {
    yield takeEvery(UPDATEFCM, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(FCMService.updateFCM, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('FCM Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* sendFcmMessage() {
    yield takeEvery(SENDFCM, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(FCMService.sendFCM, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('FCM Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewFCM),
        fork(updateThisFCM),
        fork(sendFcmMessage)
    ]);
}
