import fetch from "../auth/FetchInterceptor";

const SMTPService = {}

SMTPService.createNewSMTP = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createSMTP',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

SMTPService.updateSMTP = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateSMTP',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

SMTPService.getSMTPData = function (data) {
    return fetch({
        crossDomain:true,
        url: '/SMTPData/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}

SMTPService.deleteSMTP = function (id) {
    return fetch({
        crossDomain:true,
        url: '/deleteSMTP/'+id,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    })
}

SMTPService.changeSMTPStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateSMTPStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

export default SMTPService