import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import PaymentGatewayService from 'services/PaymentGatewayService';
import {showAuthMessage} from "../actions/Auth";
import {NEWGATEWAY,UPDATEGATEWAY} from "../constants/Gateway";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";


export function* createNewPaymentGateway() {
    yield takeEvery(NEWGATEWAY, function* ({payload}) {
        try {
            const gateway = yield call(PaymentGatewayService.createNewGateway, payload);
            if (gateway.message) {
                yield put(showAuthMessage(gateway.message));
            } else {
                yield put(showAuthMessage('Payment Gateway created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisGateway() {
    yield takeEvery(UPDATEGATEWAY, function* ({payload}) {
        try {
            const gateway = yield call(PaymentGatewayService.updateGateway, payload);
            if (gateway.message) {
                yield put(showAuthMessage(gateway.message));
            } else {
                yield put(showAuthMessage('Payment Gateway Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewPaymentGateway),
        fork(updateThisGateway)
    ]);
}
