// import dotenv from "react-dotenv";

const dev = {
	API_ENDPOINT_URL: 'http://localhost:9000/api/',
	BASE_URL: 'http://localhost:9000',
};

const prod = {
	API_ENDPOINT_URL: 'https://fohgah-backend.fohgah.com/api/',
	BASE_URL: 'https://fohgah-backend.fohgah.com',
};

const staging = {
	API_ENDPOINT_URL: 'https://fohgah-backend-staging.disruptem.com/api/',
	BASE_URL: 'https://fohgah-backend-staging.disruptem.com',
};

const qa = {
	API_ENDPOINT_URL: 'https://fohgah-backend-qa.disruptem.com/api/',
	BASE_URL: 'https://fohgah-backend-qa.disruptem.com',
};


const getEnv = () => {
	if(!process.env.REACT_APP_ENV) return staging;
	switch (process.env.REACT_APP_ENV) {
		case 'dev':
			return dev
		case 'prod':
			return prod
		case 'qa':
			return qa
		case 'staging':
			return staging
		default:
			break;
	}
}

console.log(process.env)

export const env = getEnv()
console.log(env);