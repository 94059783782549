import fetch from "../auth/FetchInterceptor";

const PaymentGatewayService = {}


PaymentGatewayService.createNewGateway = function (data) {
    return fetch({
        crossDomain:true,
        url: '/createGateway',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

PaymentGatewayService.changePayStatus = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateGatewayStatus/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        }
    })
}

PaymentGatewayService.updateGateway = function (data) {
    return fetch({
        crossDomain:true,
        url: '/updateGateway',
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
        data: JSON.stringify(data)
    })
}

PaymentGatewayService.getGatewayData = function (data) {
    return fetch({
        crossDomain:true,
        url: '/getGateway/'+data,
        method: 'get',
        headers: {
            'public-request': 'true',
            'Content-type': 'application/json',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *'
        },
    }).then(data => {
        return data
    });
}
export default PaymentGatewayService