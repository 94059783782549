import {all, takeEvery, put, fork, call} from "redux-saga/effects";
import {
  AUTH_TOKEN,
  AUTH_LOCALE,
  THEME_DIR,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
  LOAD_LOCAL
} from "../constants/Auth";
import {DIR_RTL, DIR_LTR} from "../../constants/ThemeConstant";

import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from "../actions/Auth";

import JWTService from "services/JwtAuthService";

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
    const {email, password} = payload;
    try {
      const user = yield call(JWTService.loginRequest, email, password);
      
      const langIndex = 1;
      const langs = yield call(JWTService.getAllLanguageStringsByLang, {
        pageIndex: 1,
        limit: 1000,
      });
      console.log(langs);

      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        if (user.user_type === "is_admin") {
          localStorage.setItem("auth_id", user.id);
          localStorage.setItem(AUTH_TOKEN, user.token);
          localStorage.setItem(AUTH_LOCALE, user.locale);
          if (user.locale === "ar") {
            localStorage.setItem(THEME_DIR, DIR_RTL);
            window.location.reload(false);
          } else {
            localStorage.setItem(THEME_DIR, DIR_LTR);
          }
          yield put(authenticated(user));
        } else {
          yield put(showAuthMessage("Not an admin"));
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      // const signOutUser = yield call(JWTService.signOutRequest);
      // if (signOutUser === undefined) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_LOCALE);
      localStorage.removeItem(THEME_DIR);
      yield put(signOutSuccess());
      // }

      // else {
      // 	yield put(showAuthMessage(signOutUser.message));
      // }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
    const {email, password} = payload;
    try {
      const user = yield call(JWTService.signUpEmailRequest, email, password);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(JWTService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(JWTService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}


export function* loadLocal() {
  yield takeEvery(LOAD_LOCAL, function* () {
    try {
      const langs = yield call(JWTService.getAllLanguageStringsByLang, {
        pageIndex: 1,
        limit: 1000,
      });
    } catch (error) {
      // yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
    fork(loadLocal),
  ]);
}
