import { AUTH_TOKEN } from "redux/constants/Auth";
import fetch from "../auth/FetchInterceptor";

const notificationService = {}

notificationService.updateNotification = function (data) {
    return fetch({
        crossDomain:true,
        url: '/notification/update',
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-Type': 'application/json; charset=utf-8',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *',
            'authorization': localStorage.getItem(AUTH_TOKEN)
        },
        data: data
    })
}

notificationService.getAll = function (data) {
    return fetch({
        crossDomain:true,
        url: '/notification/list/all',
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-Type': 'application/json; charset=utf-8',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *',
            'authorization': localStorage.getItem(AUTH_TOKEN)
        },
        data: data
    })
}

notificationService.getUnReadNotification = function (data) {
    return fetch({
        crossDomain:true,
        url: '/notification/list/unread/'+data.limit +'/'+ data.page,
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'public-request': 'true',
            'Content-Type': 'application/json; charset=utf-8',
            'Origin': '*',
            'Access-Control-Allow-Origin':' *',
            'authorization': localStorage.getItem(AUTH_TOKEN)
        },
        data: data
    })
}

export default notificationService