import React, { useEffect } from "react";
import {Menu, Dropdown, Avatar, Switch} from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined,
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

import {
    toggleCollapsedNav,
    onNavTypeChange,
    // onNavStyleChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onSwitchTheme,
    onDirectionChange
} from 'redux/actions/Theme';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { translateStr } from "utils/translate";

const ListOption = ({name, selector, disabled, vertical}) => (
    <div className={` ${vertical? '' : 'd-flex align-items-center justify-content-between'}`}>
        <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical? 'mb-3' : ''}`}>{name}</div>
        <div>{selector}</div>
    </div>
)

const menuItem = [
	{
		title: translateStr("admin.editProfile"),
		icon: EditOutlined ,
		path: "/app/settings/edit-profile"
    },
    
    /*{
		title: "Account Setting",
		icon: SettingOutlined,
		path: "/"
    },
    {
		title: "Billing",
		icon: ShopOutlined ,
		path: "/"
	},*/
    {
		title: translateStr("admin.HelpText"),
		icon: QuestionCircleOutlined,
		path: "/"
	}
]

export const NavProfile = ({
       signOut,
       currentTheme,
       onHeaderNavColorChange,
       onSwitchTheme,
       direction,
       onDirectionChange
   }) => {
const { switcher, themes } = useThemeSwitcher();

useEffect(()=>{
  const isChecked = localStorage.getItem('theme-type');
  const changedTheme = isChecked == "true" ? 'dark' : 'light'
  onSwitchTheme(changedTheme)
  switcher({ theme: themes[changedTheme] });
},[])

const toggleTheme = (isChecked) => {

    localStorage.setItem('theme-type',isChecked);
    onHeaderNavColorChange('')
    const changedTheme = isChecked ? 'dark' : 'light'
    onSwitchTheme(changedTheme)

    switcher({ theme: themes[changedTheme] });
};
  const profileImg = "/img/avatars/logo.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">Fohgah Admin</h4>
            <span className="text-muted">Admin</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.legth + 1}>
              <div className="mb-5">
                  <ListOption
                      name="Dark Theme:"
                      selector={
                          <Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
                      }
                  />
              </div>
          </Menu.Item>

        <Menu.Item key={menuItem.legth + 2} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">{translateStr("admin.signOut")}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({ theme }) => {
    const { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction } =  theme;
    return { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction }
};

const mapDispatchToProps = {
    toggleCollapsedNav,
    onNavTypeChange,
    // onNavStyleChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onSwitchTheme,
    onDirectionChange,
    signOut
}

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
