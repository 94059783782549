import {
    FETCH_DISCOUNT,
    FETCH_DISCOUNT_ONE,
    CREATE_DISCOUNT,
    CREATE_DISCOUNT_SUCCESS,
    UPDATE_DISCOUNT,
    DELETE_DISCOUNT,
    FETCH_DISCOUNT_SUCCESS
  } from "../actions/AppDiscount";
  
  const initState = {
    loading: false,
    message: "",
    redirect: '',
  };
  
  const reducer = (state = initState, action) => {
    switch (action.type) {
      case FETCH_DISCOUNT: {
        return {
          ...state,
          loading: false,
        };
      }
      case FETCH_DISCOUNT_SUCCESS: {
        return {
          ...state,
          coupons: action.payload,
          loading: false,
        };
      }
      case FETCH_DISCOUNT_ONE: {
        return {
          ...state,
          loading: false,
        };
      }
      case CREATE_DISCOUNT: {
        return {
          ...state,
          loading: false,
        };
      }
      case CREATE_DISCOUNT_SUCCESS: {
        return {
          ...state,
          loading: false,
          redirect: 'list',
        };
      }
      case UPDATE_DISCOUNT: {
        return {
          ...state,
          loading: false,
        };
      }
      case DELETE_DISCOUNT: {
        return {
          ...state,
          loading: false,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  