import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import SocialLinkService from 'services/SocialLinksService'
import {NEWSOCIALLINK, UPDATESOCIALLINK} from "../constants/SocialLinks";
import {showAuthMessage} from "../actions/Auth";
import {AUTH_LOCALE, AUTH_TOKEN, THEME_DIR} from "../constants/Auth";

export function* createNewSocialLink() {
    yield takeEvery(NEWSOCIALLINK, function* ({payload}) {
        try {
            const user = yield call(SocialLinkService.createNewSocialLink, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('Social Link created Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export function* updateThisSocialLink() {
    yield takeEvery(UPDATESOCIALLINK, function* ({payload}) {
        console.log(payload);
        try {
            const user = yield call(SocialLinkService.updateSocialLink, payload);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                yield put(showAuthMessage('Social Link Updated Successfully!'));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
            if(err){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_LOCALE);
                localStorage.removeItem(THEME_DIR);
                window.location.reload(false);
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createNewSocialLink),
        fork(updateThisSocialLink)
    ]);
}
